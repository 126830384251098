<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl {{ detail.plate_number ? detail.plate_number : '-' }}
            .header-cta
                .flex.items-center
                    .block
                        router-link.btn-default(to="/vehicle")
                            IconSet(type="chevron-left")
                            span.m-hide.ml-3 Back

        .card__body.p-4.pt-0
            .card
                .info-targets-vehicle
                    
                    .rollout
                        span.text-xs No Polisi 
                        p.text-light {{ detail.data.plate_number ? detail.data.plate_number : '-' }}
                    
                    .rollout
                        span.text-xs Pemilik 
                        p.text-light {{ detail.data.pemilik ? detail.data.pemilik : '-' }}
                    
                    .rollout
                        span.text-xs Alamat 
                        p.text-light {{ detail.data.alamat ? detail.data.alamat : '-' }}
                    
                    .rollout
                        span.text-xs Alamat 2 
                        p.text-light {{ detail.data.alamat ? detail.data.alamat : '-' }}
                    
                    .rollout
                        span.text-xs Kode Lokasi 
                        p.text-light {{ detail.data.kode_lokasi ? detail.data.kode_lokasi : '-' }}
                    
                    .rollout
                        span.text-xs Merk 
                        p.text-light {{ detail.data.merk ? detail.data.merk : '-' }}
                    
                    .rollout
                        span.text-xs Jenis Kendaraan 
                        p.text-light {{ detail.data.jenis ? detail.data.jenis : '-' }}
                    
                    .rollout
                        span.text-xs Golongan Kendaraan 
                        p.text-light {{ detail.data.golongan ? detail.data.golongan : '-' }}
                    
                    .rollout
                        span.text-xs Tipe 
                        p.text-light {{ detail.data.type ? detail.data.type : '-' }}
                    
                    .rollout
                        span.text-xs Model 
                        p.text-light {{ detail.data.model ? detail.data.model : '-' }}
                    
                    .rollout
                        span.text-xs Rakit 
                        p.text-light {{ detail.data.rakit ? detail.data.rakit : '-' }}
                    
                    .rollout
                        span.text-xs Silinder 
                        p.text-light {{ detail.data.silinder ? detail.data.silinder : '-' }}
                    
                    .rollout
                        span.text-xs Warna 
                        p.text-light {{ detail.data.warna ? detail.data.warna : '-' }}
                    
                    .rollout
                        span.text-xs No. Rangka 
                        p.text-light {{ detail.data.no_rangka ? detail.data.no_rangka : '-' }}
                    
                    .rollout
                        span.text-xs No. Mesin 
                        p.text-light {{ detail.data.no_mesin ? detail.data.no_mesin : '-' }}
                    
                    .rollout
                        span.text-xs TNKB 
                        p.text-light {{ detail.data.tnkb ? detail.data.tnkb : '-' }}
                    
                    .rollout
                        span.text-xs BBM 
                        p.text-light {{ detail.data.bbm ? detail.data.bbm : '-' }}
                    
                    .rollout
                        span.text-xs Nopol Lama 
                        p.text-light {{ detail.data.nopol_lama ? detail.data.nopol_lama : '-' }}
                    
                    .rollout
                        span.text-xs Jumlah Asuransi 
                        p.text-light {{ detail.data.asuransi ? detail.data.asuransi : '-' }}
                    
                    .rollout
                        span.text-xs STS Pemilik 
                        p.text-light {{ detail.data.sts ? detail.data.sts : '-' }}
                    
                    .rollout
                        span.text-xs No. BPKB 
                        p.text-light {{ detail.data.no_bpkb ? detail.data.no_bpkb : '-' }}
                    
                    .rollout
                        span.text-xs Tanggal BPKB 
                        p.text-light {{ detail.data.tgl_bpkb ? detail.data.tgl_bpkb : '-' }}
                    
                    .rollout
                        span.text-xs No. Faktur 
                        p.text-light {{ detail.data.no_faktur ? detail.data.no_faktur : '-' }}
                    
                    .rollout
                        span.text-xs tanggal. Faktur 
                        p.text-light {{ detail.data.tgl_faktur ? detail.data.tgl_faktur : '-' }}
                    
                    .rollout
                        span.text-xs Tanggal STNK 
                        p.text-light {{ detail.data.tgl_stnk ? detail.data.tgl_stnk : '-' }}
                    
                    .rollout
                        span.text-xs Tanggal Pajak 
                        p.text-light {{ detail.data.tgl_pajak ? detail.data.tgl_pajak : '-' }}
                    
                    .rollout
                        span.text-xs Tanggal STNK 
                        p.text-light {{ detail.data.tgl_stnk ? detail.data.tgl_stnk : '-' }}
                    
                    .rollout
                        span.text-xs Tanggal Pajak 
                        p.text-light {{ detail.data.tgl_pajak ? detail.data.tgl_pajak : '-' }}
                    
                    .rollout
                        span.text-xs Tanggal Daftar 
                        p.text-light {{ detail.data.tgl_daftar ? detail.data.tgl_daftar : '-' }}
                    
                    .rollout
                        span.text-xs Jenis Daftar 
                        p.text-light {{ detail.data.jenis_daftar ? detail.data.jenis_daftar : '-' }}
                    
                    .rollout
                        span.text-xs Kode Samsat 
                        p.text-light {{ detail.data.code_samsat ? detail.data.code_samsat : '-' }}
                    
                    .rollout
                        span.text-xs Kode Polda 
                        p.text-light {{ detail.data.code_polda ? detail.data.code_polda : '-' }}
                    
                    .rollout
                        span.text-xs No. KTP 
                        p.text-light {{ detail.data.nik ? detail.data.nik : '-' }}
                    
                    .rollout
                        span.text-xs No. Handpone 
                        p.text-light {{ detail.data.hp ? detail.data.hp : '-' }}
                    
                    .rollout
                        span.text-xs No Polisi 
                        p.text-light {{ detail.data.plate_number ? detail.data.plate_number : '-' }}
                    
                    .rollout
                        span.text-xs Pemilik 
                        p.text-light {{ detail.data.pemilik ? detail.data.pemilik : '-' }}
                    
                    .rollout
                        span.text-xs Tanggal Mati 
                        p.text-light {{ detail.data.tgl_mati ? detail.data.tgl_mati : '-' }}
                    
                    .rollout
                        span.text-xs Samsat Bayar 
                        p.text-light {{ detail.data.samsat_bayar ? detail.data.samsat_bayar : '-' }}
                    
                    .rollout
                        span.text-xs Kode Jenis 
                        p.text-light {{ detail.data.code_jenis ? detail.data.code_jenis : '-' }}
                    
                    .rollout
                        span.text-xs Kode Golongan 
                        p.text-light {{ detail.data.code_golongan ? detail.data.code_golongan : '-' }}


</template>

<script>
import IconSet from '@/components/IconSet.vue'
import { mapState } from "vuex";
import Spinner from "@/components/Spinner.vue";

export default {
    components: {
        IconSet,
        Spinner,
    },

    data() {
        return {

        }
    },

    computed:{
        ...mapState("vehicle", [
            "status_detail",
            "detail",
        ]),
        route_name() {
            return this.$route.name;
        },    
        id() {
            return this.$route.params.id;
        },
    },    
    
    watch: {       
        id() {
            this.getData();
        },
        items_per_page() {
            this.getData();
        },    
    },

    methods: {
        getData() {
            if (this.route_name === 'vehicle-detail') {
                EventEmit.$emit('showLoading', true);

                this.$store.dispatch('vehicle/getDetail', this.id)
                .then((resp) => {
                    if (resp.result === 'success') {
                        EventEmit.$emit('showLoading', false);
                    } else {
                        EventEmit.$emit('error');
                    }
                });
            }
        },         
    },

    created() {
        
    },
    mounted() {
        this.getData();
    },    
    
}
</script>

<style lang="sass">
.info-targets-vehicle
    display: grid
    grid-template-rows: repeat(10, minmax(0, 1fr))
    grid-template-columns: repeat(4, minmax(0, 1fr))
    grid-auto-flow: column
    
</style>